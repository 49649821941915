/*!
 * Bootstrap v2.3.2
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
// Non SCSS
@import "../webfonts/stylesheet.css";

// Core variables and mixins
@import "variables"; // Modify this for custom colors, font-sizes, etc
@import "mixins";

// CSS Reset
@import "reset";

// Grid system and page structure
@import "scaffolding";
@import "grid";
@import "layouts";

// Base CSS
@import "type";
@import "code";
@import "forms";
@import "tables";

// Components: common
@import "sprites";
@import "dropdowns";
@import "wells";
@import "component-animations";
@import "close";

// Components: Buttons & Alerts
@import "buttons";
@import "button-groups";
@import "alerts"; // Note: alerts share common CSS with buttons and thus have styles in buttons

// Components: Nav
@import "navs";
@import "navbar";
@import "breadcrumbs";
@import "pagination";
@import "pager";

// Components: Popovers
@import "modals";
@import "tooltip";
@import "popovers";

// Components: Misc
@import "thumbnails";
@import "media";
@import "labels-badges";
@import "progress-bars";
@import "accordion";
@import "carousel";
@import "hero-unit";
@import "responsive-embed";
@import "flexbox";

// Utility classes
@import "user";
@import "user2";
@import "widgets";

@import "utilities"; // Has to be last to override when necessary
