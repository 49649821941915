body{
	padding-left: $sidebarWidth;
	.embed &,
	&.hide-menu{
		padding-left: 0;
	}
}
iframe{
	max-width:100%;
}
$sidebarHeight:900px;
$footerHeight:300px;
.sidebar{
	width: $sidebarWidth;
	position: absolute;
	left: 0;
	z-index: 10;
	&,
	.current-menu-item > a,
	.current-menu-ancestor > a,
	a:hover,
	a:focus,
	a:active{
		text-decoration: none;
		color:$white;
	}
	#menu-left-nav-menu{
		margin-left: 20px;
		display: none;
	}
	#menu-left-nav-menu,
	#menu-left-nav-menu li,
	#menu-left-nav-menu li a{
		@include f-lg(30,30, normal);
		text-transform: uppercase;
	}
	#menu-left-nav-menu li.rise-menu-item,
	#menu-left-nav-menu li.rise-menu-item > a{
		line-height:26px;
		// color:$red;
		margin: 8px 0;
	}
	#menu-left-nav-menu > li{
		margin-bottom: 5px;
	}
	#menu-left-nav-menu .sub-menu,
	#menu-left-nav-menu .sub-menu li,
	#menu-left-nav-menu .sub-menu li a{
		@include f-lg(18,17, normal);
		list-style: none;
	}
	#menu-left-nav-menu .sub-menu a:before{
		content:" > ";
		color:$pink;
		// todo: fix hover
	}
	#menu-left-nav-menu .sub-menu{
		margin-bottom: 4px;
		margin-left: 5px;
	}
	.sidebar-aside{
		background-color: $gray;
		padding:10px 5px 10px 25px;
		.goog-te-gadget-icon{
			display: none;
		}
		.goog-te-gadget-simple {
			border: none;
			padding-top: 0;
			padding-bottom: 0;
			.goog-te-menu-value span{
				@include f-os(12,8,300);
			}
		}
		#google_translate_element {
			padding-bottom: 10px;
			.goog-te-menu-value {
				display: inline-block;
				width: 100%;
				span{
					color: $pink !important;
					&:first-child{
						color:$grayDark !important;
						width: 104px;
						display: inline-block;
					}
				}
			}
		}
	}
	ul.social{
		margin-bottom: 0;
	}
	#searchform{
		margin-bottom: 0;
		input{
			width: 100%;
			@include border-radius(0);
			@include f-os(12,8,300);
			color: $grayDark;
			height: 20px;
			line-height: 20px;
			padding: 0 4px;
			border: 0;
			vertical-align: top;
			margin-bottom: 0;
		}
		#searchchevron{
			background: -67px -22px no-repeat;
			.no-svg &,
			.no-js &{
				background-image: url("../img/arrow-sprite.png?v=#{$cacheBust}");
			}
			.svg &{
				background-image: url("../svg/arrow-sprite.svg?v=#{$cacheBust}");
			}
			width: 21px;
			height: 22px;
			border:0;
			text-indent: -9999px;
			overflow: hidden;
			padding: 0;
			margin-left: 1px;
			position: relative;
			left: -1px;
			&:hover{
				background-position: -67px -1px;
			}
		}
	}
	.signup-link{
		width: 185px;
		display: block;
		text-indent: -99999px;
		overflow: hidden;
		height: 139px;
		background: top left no-repeat;
		.no-js &,
		.no-svg &{
			background-image: url("../img/signup-185.png?v=#{$cacheBust}");
		}
		.svg &{
			background-image: url("../svg/signup-sprite.svg?v=#{$cacheBust}");
		}
		&:hover{
			background-position: 0 -139px;
		}
	}
	.brand{
		background: top left no-repeat;
		width: 158px;
		height: 229px;
		@include hide-text();
		margin: 15px 0;
		display: block;
		.no-svg &,
		.no-js & {
			background-image: url("../img/brand-logo.png?v=#{$cacheBust}");
		}
		.svg & {
			background-image: url("../svg/1billion_revolution_logo-2017.svg?v=#{$cacheBust}");
		}
	}
	.phone-header{
		display: none;
	}
}
.search-title a{
	color: $black;
	text-transform: uppercase;
	&:hover{
		color: $pink;
	}
}
.flex-caption input[type="text"],
.rise-widget input[type="text"]{
	width: 128px;
	margin-bottom: 10px;
	border: 0
}
.flex-caption input[type="submit"],
.rise-widget input[type="submit"],
#gform_submit_button_1,
#gform_submit_button_3{
	width: 185px;
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	height: 139px;
	background: url("../img/signup-185.png?v=#{$cacheBust}") -22px 0 no-repeat;
	border: 0;
	&:hover{
		background-position: -22px -139px;
	}
}
#gform_submit_button_1,
#gform_submit_button_3{
	background: url("../img/submit-sprite.png?v=#{$cacheBust}") 0 0 no-repeat;
	width: 132px;
	height: 132px;
	&:hover{
		background-position: 0 -132px;
	}
	&.clicked,
	&:hover.clicked{
		background-image: url("../img/Butterfly.gif?v=#{$cacheBust}");
		background-position: 0 0 ;
	}

}
@media (min-height: $sidebarHeight) {
	.sidebar{
		position: fixed;
	}
}
.sidebar-archive {
	.rr-header{
		@include f-lg(17,20);
		text-transform: uppercase;
		margin: 0;
	}
	.rr-items{
		@include f-os(12,16);
		text-transform: capitalize;
		margin-bottom: 10px;
	}
}
.share-container .sidebar-archive {
	.rr-header{
		color: $white;
	&.share-cta{
		margin: 1em 0;
	}
	}
}
.page-title{
	@include f-lg(65,65);
	text-transform: uppercase;
	margin-top: 0;
}
.deck{
	@include f-os(18,23);
	margin-bottom: 20px;
}
.speech-bubble-right{
	// background:$white;
	// @include border-radius(5px);
	// position: relative;
	background: url("../img/blogbubble.png?v=#{$cacheBust}") top left no-repeat;
	background-size: 100% 100%;
	.inner{
		padding: 10%;
		// &:after{
		// 	content: " ";
		// 	background:$white;
		// 	@include rotate(-45deg);
		// 	position: absolute;
		// 	bottom: 10px;
		// 	right: -4px;
		// 	height: 10px;
		// 	width: 10px;
		// 	display: block;
		// }
		h1{
			@include f-lg(46,38);
			text-transform: uppercase;
		}
	}

}

.sidenav-inner{
	padding: 10px;
	&.sidebar-lower{
		padding: 0;
	}
}
.container,
.container-fluid{
	padding: 0;
	background: $containerBackground;
	max-width: 1600px;
}
.archive-container,
.page-container{
	& > .row-fluid {
		// padding:10px 0;
	}
}
.home,
.page-template-homepage-widgets-php,
.page-template-homepage-no-widgits-php
{
	.news-blog-section{
		padding: 20px $sidebarWidth 20px 0;
		background: $red;
		h1 a{
			color: $black;
			&:hover{
				color: $pink;
			}
		}
	}
	.blog-item,
	.event-item,
	.widget-page-item{
		background-color: $white;
		padding: 10px;
		h4,
		h5,
		h4 a,
		h5 a{
			color: $red;
			font-weight: normal;
			text-transform: uppercase;
			margin: 0;
		}
		h4{
			@include f-lg(27,25,400);
		}
		h5{
			@include f-os(14,18,normal);
			margin: 2px 0 5px 0;
		}
		img{
			margin-bottom: 10px;
		}
	}
}
.read-more{
	@include f-lg(17,17,400);
	color:$grayDark;
	text-transform: uppercase;
	display: block;
	margin-top: 10px;
	padding-left: 24px;
	position: relative;
	&, &:hover{
		text-decoration: none;
	}
	i{
		position: absolute;
		left: 0;
		top: 0;
		display: block;

	}
	&:hover {
		color:$pink;
		i{
			background-position: top left;
		}
	}
}
i.obr-icon{
	display: inline-block;
	background: url("../img/read-more-chevron-sprite.png?v=#{$cacheBust}") bottom left no-repeat;
	width: 17px;
	height: 17px;
	&:hover {
		background-position: top left;
	}
}
#events_listing{
	ul.inline > li, ol.inline > li{
		padding-left: 0;
		padding-right: 10px;
	}
	.event-listing-title a{
		color: $black;
		&:hover{
			color:$pink;
		}
	}
}
input.toggle-check{
	margin-bottom: 8px;
}

.map,
.map * {
	@include box-sizing(content-box);
	img{
		max-width: none;
	}
}
.gm-style img {
	max-width: none;
}

::-webkit-input-placeholder {
   color: $grayDark;
   opacity:1;
}

:-moz-placeholder { /* Firefox 18- */
   color: $grayDark;
   opacity:1;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: $grayDark;
   opacity:1;
}

:-ms-input-placeholder {
   color: $grayDark;
   opacity:1;
}
.responsive-media-wrapper{
	position: relative;
	margin: 0;
	&.youtube{
		padding-top: 25px;
	}
	padding-bottom: 56.25%; // default to 16-9
	&.ratio-19-9{
		padding-bottom: 56.25%;
	}
	&.ratio-4-3{
		padding-bottom: 75%;
	}
	.responsive-media-inner-wrapper{
		div,
		object,
		embed,
		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			* {
				margin: 0;
				padding: 0;
			}
		}
	}
}


.rebelmouse-container{
	.widgettitle{
		display: none;
	}
}

#footer{
	background-color: $grayDark;
	.container-fluid{
		background: none;
	}
	padding: 20px $sidebarWidth 20px 0;
	&, a {
		color:$white;
	}
	a:hover{
		color:$pink;
		text-decoration: none;
	}
	.signup-link{
		margin-bottom: 10px;
		display: block;
	}
	h5{
		@include f-lg(17,17);
		text-transform: uppercase;
		margin-bottom: 7px;
	}
	li,
	li a,
	h6,
	h6 a{
		@include f-os(13,17, 300);

	}

}
i.arrow-right,
i.arrow-left{
	vertical-align: absmiddle;
	display: inline-block;
	height: 17px;
	width: 17px;
	background-image: url("../img/v-arrow-sprite-small.png?v=#{$cacheBust}");
	background-position: 0 0;
	// margin-left: 10px;

	background-repeat: no-repeat;
}
i.arrow-right{
	background-position: -21px 0;
	*:hover > &,
	&:hover{
		background-position: -21px -21px;
	}
	.share-navigation &{
		background-position: -21px -21px;
	}
}
i.arrow-left{
	// margin-right: 10px;
	*:hover > &,
	&:hover{
		background-position: 0 -21px;
	}
	.share-navigation &{
		background-position: 0 -21px;
	}
}
.share-prev-link, .share-next-link{
	margin-bottom: 5px;
}

.blog-item{
	margin-bottom: 20px;
	img.wp-post-image{
		margin-bottom: 10px;
	}
}
.blog-navigation{
	width: 100%;
	@include f-lg(30, 30);
	a{
		text-transform: uppercase;
		color: $grayDark;
		&:hover{
			color: $pink;
		}
	}
	padding: 30px 0;
}
ul.social{
	li{
		padding: 0;
		padding-right: 4px;
		&:last-child{
			padding-left: 0;
		}
	}
}
.social-icon{
	background: top left no-repeat;
	.no-svg &,
	.no-js &{
		background-image: url("../img/social-sprite.png?v=#{$cacheBust}") ;
	}
	.svg &{
		background-image: url("../svg/social-sprite.svg?v=#{$cacheBust}") ;
	}
	width: 22px;
	height: 22px;
	$ofs: -22px;
	text-indent: -9999px;
	overflow: hidden;
	display: block;
	&.social-fb{
		background-position: $ofs*0 0;
	}
	a:hover > .social-fb,
	&:hover.social-fb{
		background-position: $ofs*0 $ofs;
	}
	&.social-tw{
		background-position: $ofs*1 0;
	}
	a:hover > .social-tw,
	&:hover.social-tw{
		background-position: $ofs*1 $ofs;
	}
	&.social-tm{
		background-position: $ofs*2 0;
	}
	a:hover > .social-tm,
	&:hover.social-tm{
		background-position: $ofs*2 $ofs;
	}
	&.social-em{
		background-position: $ofs*3 0;
	}
	a:hover > .social-em,
	&:hover.social-em{
		background-position: $ofs*3 $ofs;
	}
	&.social-ig{
		background-position: $ofs*4 0;
	}
	a:hover > .social-ig,
	&:hover.social-ig{
		background-position: $ofs*4 $ofs;
	}
}
.page-content ul.social li{
	@include f-lg(32, 32);
	a{
		color: $black;
		&:hover{
			color: $pink;
		}
	}
	i {
		margin: 5px 10px 0 0;
		float: left;
	}
}

#footer h6.copyright-info,
#footer h6.copyright-info a {
	@include f-os(11,15, 300);
}
// prevent Uglyness on posts with little content.

.single-container,
.page-container,
.event-container,
.archive-container{
	min-height: $sidebarHeight ;
	padding-top: 25px;
	padding-bottom: 25px;
	&.hide-title{
		padding-top: 0;
		padding-bottom: 0;
		max-width: none;
	}
}
.share-container{
	background: #353535 !important;
	.share-title, .share-item-content, .share-title a {
		color: $white;
	}
	.share-title a:hover {
		color: $pink;
	}
	.share-item-title, .share-author {
		color:$red;
	}
}


// add no-collapse class for spans.

.row-fluid.no-collapse{
    // generate .spanX and .offsetX
    @include grid-fluid-span-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth);
    @include grid-fluid-offset-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth);
}

// Flex Slider
// -------------------------
.flexslider .slides > li{
	position: relative;
	.flex-caption{
		position: absolute;
		top: 5%;
		right: 0;
		width: 30%;
		color: $white;
		background: url("../svg/caption.opt.svg?v=#{$cacheBust}") top left no-repeat;
		background-size: 100% 100%;
		@include f-os(18,20,400);
		text-transform: uppercase;
		max-height: 80%;
			padding: 2em 1em 1em 2em;
			overflow-y: auto;
		.inner-wrap{
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
		&.affixToSide{
			background: rgba(red($pink), green($pink), blue($pink), 0.75);
			top: 0;
			max-height: 100%;
			min-height: 100%;
			height: 100%;
			padding-top:0;
			padding-bottom: 0;
			.inner{
				padding-top:20px;
				padding-bottom: 40px;

			}
		}
	}
}
img.pull-left{
	margin-right: 10px;
}
img.pull-right{
	margin-left: 10px;
}
i.v-icon{
	background: 0 0 url("../img/v-icon-s.png?v=#{$cacheBust}");
	width: 18px;
	height: 18px;
	display: inline-block;
	margin-right: 2px;
	vertical-align: absmiddle;
	@include transition(all);
	@include transition-duration(1s);
	a.active > &{
		@include rotate(-180deg);
	}
}

// Gform overrides:

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning{
	background: none;
    border-bottom: none;
    border-top: none;
}
.gform_wrapper .gfield_required{
	color: $pink;
}
.gform_wrapper div.validation_error,
.gform_wrapper .validation_message,
.gform_wrapper .gfield_error .gfield_label,
.gform_wrapper .gfield_error .gfield_required{
	color: $red;
}
.gform_wrapper li.gfield_error input[type="text"],
.gform_wrapper li.gfield_error input[type="url"],
.gform_wrapper li.gfield_error input[type="email"],
.gform_wrapper li.gfield_error input[type="tel"],
.gform_wrapper li.gfield_error input[type="number"],
.gform_wrapper li.gfield_error input[type="password"],
.gform_wrapper li.gfield_error textarea{
	border: 1px solid $red;
}
.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description,
.gform_wrapper .instruction,
.gform_wrapper div.charleft{
	@include f-os(14,14);
	color: $grayDark;
}


.gform_wrapper ul.gfield_checkbox li,
.gform_wrapper ul.gfield_radio li{
	float: left;
	padding-right: 10px !important;

}

// This is what justice looks like
$ugcsharewidget:250px;
.ugcshare-posts{
	.ugcshare-post{
		width: 300px;
		.collection-widget & {
			width: $ugcsharewidget;
		}
		position: relative;
		.ugcshare-post-inner{
			padding-bottom: 100%;
			.bg, .bubble, .ugcshare-post-content{
				position: absolute;
				&, img{
					width: 300px;
					height: 300px;
				}
				.collection-widget & {
					width: $ugcsharewidget;
					height: $ugcsharewidget;
					img{
						width: $ugcsharewidget;
						height: $ugcsharewidget;
					}
				}
			}
			.bubble{
				background-color: rgba(#353535, 0.2);
			}
			.bubble.hover{
				background-color: transparent;
			}
			.ugcshare-post-content{
				display: table;
				.ugcshare-post-content-inner{
					width: 300px;
					height: 300px;
					.collection-widget & {
						width: $ugcsharewidget;
						height: $ugcsharewidget;
						@include f-lg(25,24);
					}
					display: table-cell;
					text-align: center;
					vertical-align: middle;
					color: $white;
					@include f-lg(38,36);
					&:hover, &:active, &:focus{
						background-color: transparent;
					}
					.innerinner{
						padding: 30px;
						padding-bottom: 43px;
					}
				}
			}
		}
		.ugcshare-post-author{
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			color: $white;
			text-align: center;
		}
	}
	.color-red .bg{
		background-color: $red;
	}
	.color-white {
		.bg{
			background-color: $white;
		}
		.ugcshare-post-content-inner{
			color: $black !important;
		}
		&.has-video .ugcshare-post-content-inner,
		&.has-image .ugcshare-post-content-inner{
			color: $white !important;
		}
	}
	.has-video{
		.innerinner{
			padding-bottom: 60px !important;
		}
	}

	.color-pink .bg{
		background-color: $pink;
	}

}
.sidebar-ugcshare{
	h2{
		color: $pink;
		@include f-lg(30, 30);
		text-transform: uppercase;
		text-align: right;
		margin-top: 30px;
	}
	p{
		text-align: right;
		color: $white;
		@include f-os(13,18);
		font-weight: normal;
	}
}
.has-video .ugcshare-post-content-inner {
	background: url("../img/has-video.png?v=#{$cacheBust}") top left no-repeat;
	.collection-widget & {
		background-position: -25px -45px;

	}
}
.page-template-ugcshare-php,
.page-template-show-shares-php{
	.page-title, .page-content{
		color:$white;
	}
	.page-content{
		@include f-os(18,21);
		letter-spacing: 0.5px;
		text-transform: uppercase;
	}
}

//  Events

.event-container{
	h1, h2, h3, h4, h5, h6{
		text-transform: uppercase;
		margin: 5px 0;
		@include font-family-alt();
		font-weight: normal;
	}
	// h3{
	// 	line-height: 28px;
	// 	font-size: 32px;
	// 	margin-top: 15px;
		// &.event-title{
		// 	@include f-lg(65,65);
		// 	text-transform: uppercase;
		// 	margin-top: 0;
		// }
	// }
	h4{
		font-weight: normal;
		color: $red;
		@include f-os(16, 18);
	}
	.map{
		margin-top: 15px;
		.maps-more{
			font-size: 12px;
		}
		width:100%;
		height: 400px;
		img{
			max-width: inherit;
		}
		.gm-style .gm-style-iw h4 a {
			font-weight: normal;
			color: $red;
			@include f-os(16, 18);
		}
		.map-infowindow{
			line-height:1.35;
			overflow:hidden;
			white-space:nowrap;
		}
	}
	.event-rsvp{
		h2{
			font-size: 55px;
			margin: 20px 0 2px;
		}
		h3{
			margin-top: 0;
		}
		.text-red{
			margin-top: 15px;
		}
	}
	// .event-title{
	// 	margin-bottom: 0px;
	// }
	// .event-item-title{
	// 	margin-top: 0px;
	// 	color: $red;
	// }
	.slider{
		margin: 10px 0 0;
	}
}
.create-event-map{
	width:100%;
	height: 400px;
	margin-bottom: 10px;
	&.map-error{
		border:1px solid $red;
	}
}
.create-upload-map{
	width:100%;
	height: 300px;
	margin-bottom: 10px;
	&.map-error{
		border:1px solid $red;
	}
}
select.stateprov{
	display: none;
}

#find_events{
	margin-top: 20px;
}
// .item_vday_event{
// 	.item_icon{
// 		background: url("../img/maps/vday_marker_info.png?v=#{$cacheBust}") top left no-repeat;
// 	}
// }
// .item_obr_event{
// 	.item_icon{
// 		background: url("../img/maps/obr_marker_info.png?v=#{$cacheBust}") top left no-repeat;
// 	}
// }
.page-content,
.share-item,
.event-content,
.blog-item-content{
	ul{
		list-style:none;
		li:before {
			content: "\2022";
			list-style: disc inside none;
			margin-left: -8px;
			position: absolute;
		}
	}
}
.gform_wrapper .gfield_checkbox li input[type="checkbox"],
.gform_wrapper .gfield_radio li input[type="radio"],
.gform_wrapper .gfield_checkbox li input{
	margin-top: 7px;
}
.gform_wrapper .gfield_checkbox li label,
.gform_wrapper .gfield_radio li label{
	margin:0 6px 0 18px;
}

body .ui-widget-content{
	background:url("../img/popup.png?v=#{$cacheBust}") top left no-repeat;
	.svg & {
		background:url("../svg/popup.svg?v=#{$cacheBust}") top left no-repeat;
	}
	padding: 0;
	margin: 0;
	border: 0;
	.ui-widget-header{
		background:none;
		border:none;
	}
}
// .ui-dialog .ui-dialog-titlebar-close {
//     border:0;
//     background:none;
// 	top: 5px;
// 	left: 480px;
// 	margin: 0;
// }
.ui-dialog .ui-dialog-titlebar{
	display: none;
// 	height: 20px;
// 	width: 100%;
// 	padding: 0;
// 	position: absolute;
// 	overflow:visible;
// 	z-index:20;
}
.homepage-popup{
	display: none;
}
.ui-dialog .homepage-popup{
	display: block;
	text-transform: uppercase;
	padding: 0;
	strong{
		font-weight: normal;
		color: $pink;
	}
	h2{
		@include f-lg(48,48, normal);
	}
	h3{
		@include f-lg(30,20, normal);

	}
	.inner{
		padding:30px 20px 10px 75px;
	}
	.closeBTN{
		position: absolute;
		height: 25px;
		position: absolute;
		right: 5px;
		top: 5px;
		width: 25px;
		cursor: pointer;

	}
	ul, li{
		list-style: none;
	}
	ul{
		margin: 25px 0;
		display: block;
	}
	li{
		display: inline-block;
		padding: 0 30px 0 50px;
		@include f-lg(30,36, normal);
		height: 36px;
		background: url("../img/v-36-right-pink.png?v=#{$cacheBust}") 0 50% no-repeat;
		.svg & {
			background: url("../svg/v-right-pink.svg?v=#{$cacheBust}") 0 50% no-repeat;
		}
		a{
			height: 36px;
			@include f-lg(30,36, normal);
		}
	}
	a{
		outline:none;
		&:focus{
			color: $black;
		}
		&:hover{
			color: $pink !important;
		}
	}
}
.embed {
	body{
		background:$white;
	}
}

.map-block.gfield_error .map-error-msg {
	color:#790000;
}
#footer #viva-logo{
	color:$pink;
}
.no-svg .svg-support,
.no-js .svg-support{
	display: none;
}
.svg .no-svg-support{
	display: none;
}


/***************************
****************************
VIDEO CENTER
****************************
***************************/
.video-center {
	padding-top: 25px;
}
.video-section {
	padding-top: 10px;
	padding-bottom: 10px;
	&:nth-of-type(odd) {
		background: #fff;
	}
	&:nth-of-type(even) {
		background: #d8d6d5;
	}
	h3.page-title {
		margin-bottom: 0;
	}
	h5 {
		margin-top: 0;
	}
	.videos{
		// overflow: hidden;
		// white-space: nowrap;
	}
	.single-video {
		margin-right: 10px;
		.video-title {
			margin-top: 10px;
			color: $red;
		}
		.video-thumb{
			background-position: 50% 50%;
			background-size: cover;
			cursor: pointer;
		}
	}
	.live-video {
		.inner-container {
			height: 0;
			transition: padding linear 500ms;
			overflow: hidden;
			padding: 0;
			background: #000;
			min-height: 0;
		}
		&.active {
			margin-bottom: 15px;
			.inner-container {
				transition: padding linear 500ms;
				position: relative;
				padding-bottom: 56.25%; /* 16:9 */
				padding-top: 25px;
				height: 0;
			}
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			width: 100%;
		}
	}
	.flexslider{
		padding-left: 10px;
	}
	.flex-direction-nav{
		.flex-next,
		.flex-prev{
			bottom: 50%;
		}
		.flex-prev{
			right: auto;
			left: -30px;
		}
		.flex-next{
			left: auto;
			right: -30px;
		}
	}
	@media (max-width:767px){
		.videos-wrapper{
			padding-left: 40px;
			padding-right: 40px;
		}
	}
}
