.rise-widget{
	background:$pink;
	p{
		color:$white;
		font-weight:normal;
		@include f-lg(27,25);
	}
}
.home-widgets-container .widget{
	padding: 20px 40px 20px 0;
	&.widget-collection-type-page{
		padding: 0 40px 0 0;
	}
	&.widget-collection-type-ugcshare-posts{
		padding: 20px 0;
	}
	@media (min-width:1600px){
		padding: 20px $sidebarWidth 20px 0;
		&.widget-collection-type-page{
			padding: 0 $sidebarWidth 0 0;
		}
		&.widget-collection-type-ugcshare-posts{
			padding: 20px 0;
		}
	}
}



.collection-widget{
	.widget-bubble{
		$widgetRight:30px;
		padding: 5px $widgetRight 5px 15px;
		position: relative;
		background:$white;
		h2{
			font-size: 24px;
			line-height: 36px;
		}
		.backgroundsize.multiplebgs &{
			background-image: 	url(../img/widget-title-bubble.png), url(../img/widget-title-bubble-arrow.png);
			background-position: 	top left, 100% 30%;
			background-repeat:  no-repeat, no-repeat;
			background-size: 	100% 100%, auto;
			background-color: transparent;
		}
		.backgroundsize.multiplebgs.svg &{
			background-image: 	url(../svg/widget-title-bubble.svg), url(../svg/widget-title-bubble-arrow.svg);
		}
		.collection-widget-title{
			position: relative;

		}
	}
	.ugcshare-post-content{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: auto;
		width: auto;
	}
}
.widget-collection-type-page .widget-bubble {
	margin-top: 20px;
	margin-bottom: 20px;
}
.widget-collection-type-ugcshare-posts .widget-bubble {
	margin-top: 20px;
	margin-bottom: 20px;
}
iframe[id^='twitter-widget-']{
	width:100% !important;
}
.fb-like-box,
.fb-like-box > span,
.fb-like-box > span > iframe{
    width: 100% !important;
}
.fb-like-box{
	background: $white;
}

.shortcode-socialembed{
	padding:20px 0;
}

//Re Widget
.rev-escalate-widget{
	position: relative;
	background-color: $red;
	.re-widget-title{
		font-family: 'Permanent Marker', cursive;
		color:$black;
		strong{
			color: $white;
		}
		text-align: center;
		font-size: 2.4em;
		line-height: 1.2;
		@media (min-width:480px){
			font-size: 1.7em;
		}
		@media (min-width:768px){
			font-size: 1.5em;
		}
		@media (min-width:980px){
			font-size: 2em;
		}
		@media (min-width:1200px){
			font-size: 3em;
		}
	}
	a{
		&,
		&:link,
		&:visited,
		&:hover,
		&:active{
			background-color: rgba(0,0,0,0);
		}

	}
	.re-slider{
		position: relative;
		@include ff-lg();

		font-size: 1em;
		line-height: 1.2;
		@media (min-width:480px){
			font-size: 1.7em;
		}
		@media (min-width:768px){
			font-size: 2em;
		}
		@media (min-width:980px){
			font-size: 2.5em;
		}
		@media (min-width:1200px){
			font-size: 3em;
		}

		.image-overlay{
			background-position: 50% 50%;
			background-size: cover;
		}
		.content-overlay{
			background-color: rgba(0,0,0,0.3);
			@include flexbox();
			@include align-items(center);
			@media (max-width:767px){
				.span15{
					padding: 0 20px;
				}
			}

			.content-headline{
				font-weight: normal !important;
				text-transform: uppercase;
				@include ff-lg();
				font-weight: normal;
				font-size: inherit;
				color:$white;
				// line-height: inherit;
				line-height: 1.2;
			}
			.content-cta{
				float: left;
				background: $red;
				color: $white;
				padding: 0.2em 2em 0.2em 0.4em;
				text-transform: uppercase;
				font-weight: normal;
				position: relative;
				font-size: 0.6em;
				height: 0.8em;
				line-height: 0.8em;
				span{
					position: relative;
					top: 0.05em;
				}
				&:before{
					content:" ";
					display: block;
					position: absolute;
					top: 0;
					left:100%;
					width: 10px;
					height: 100%;
					// background-color: #f00;
					background-position: 0 0;
					background-size: 100% 100%;
					background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxMDBweCIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwMCAxMDAiIHhtbDpzcGFjZT0icHJlc2VydmUiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxwb2x5Z29uIGZpbGw9IiNFOTMwMjkiIHBvaW50cz0iMCwwIDEwMCw1MCAwLDEwMCAiLz48L3N2Zz4=);
				}
			}
		}
	}
	.flexslider-init .custom-flex-nav {
		visibility: visible;
	}
	.custom-flex-nav{
		visibility: hidden;

		&.custom-flex-nav-prev{
			right: auto;
			left: $fluidGridGutterWidth;
		}
		&.custom-flex-nav-next{
			left: auto;
			right: $fluidGridGutterWidth * -1;
		}
		@media (max-width:767px){
			float: none;
			width: 10% !important;
			svg{
				width: 100%;
			}
			&.custom-flex-nav-prev{
				right: auto;
				left: 0;
			}
			&.custom-flex-nav-next{
				left: auto;
				right: 0;
			}
		}
		&, svg{
			position: absolute;
			top: 0;
			height: 100%;
		}
		svg{
			@include transition(all 1s);
			width: 100%;
		}
	}
	.flex-next{
		.left_curl{
			display: none;
		}
	}
	.flex-prev{
		.right_curl{
			display: none;
		}
	}
	.flexslider{
		padding: 0 10%;
		@media (min-width:768px){
			padding: 0;
		}
	}
	.svg-btn{
		.re-point,
		.re-point-text,
		.re-horn,
		.re-horn-text,
		.re-play,
		.re-play-text{
			display: none;
		}
		&.svg-btn-rise{
			.re-point{
				display: block;
			}
		}
		&.svg-btn-join{
			.re-horn{
				display: block;
			}
		}
		&.svg-btn-watch{
			.re-play{
				display: block;
			}
		}
	}
	.re-buttons{
		margin: 2em 0;
		@media (max-width:767px){
			padding: 0 10%;
		}
		.span8{
			.re-bubble{
				@include transition(all 2s);

			}
			&:nth-child(2n){
				.re-bubble{
					/*
					@include transform(
						rotate(180deg)
						translate(-96.8%, -100%)
					);
					 */
				}
			}
		}

	}
	.re-btn{
		position: relative;
		display: block;
		.btn-text{
			display: block;
			position: absolute;
			top: 50%;
			left: 35%;
			right: 5%;
			color:$red;
			@include translate(0, -50%)
			@include f-lg(20,20, normal);
			@media (min-width:480px){
				@include f-lg(24,24, normal);
			}
			@media (min-width:768px){
				@include f-lg(14,14, normal);
			}
			@media (min-width:980px){
				@include f-lg(16,16, normal);
			}
			@media (min-width:1200px){
				@include f-lg(20,20, normal);
			}
		}
		@media (max-width:767px){
			margin-bottom: 1em;
		}
	}
	.re-ticker{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		min-height: 2em;
		@include ff-lg();
		overflow: hidden;
		font-size: 1em;
		line-height: 2;
		@media (min-width:480px){
			font-size: 1.2em;
		}
		@media (min-width:768px){
			font-size: 1.2em;
		}
		@media (min-width:980px){
			font-size: 1.5em;
		}
		@media (min-width:1200px){
			font-size: 1.5em;
		}
		.rising-now,
		.countries{
			height: auto;
			text-transform: uppercase;
			position: absolute;
			top: 0;
		}
		.rising-now{
			width: 20% ;
			left: 0;
			background: $white;
			text-align: center;
			color: $pink;
			z-index: 2;
			&:before{
				content:" ";
				display: block;
				position: absolute;
				top: 0;
				left:100%;
				width: 10px;
				height: 100%;
				// background-color: #f00;
				background-position: 0 0;
				background-size: 100% 100%;
				background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxMDBweCIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwMCAxMDAiIHhtbDpzcGFjZT0icHJlc2VydmUiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxwb2x5Z29uIGZpbGw9IiNGRkZGRkYiIHBvaW50cz0iMCwwIDEwMCw1MCAwLDEwMCAiLz48L3N2Zz4=);
			}
		}
		.countries{
			width: 80% ;
			left: 20%;
			color: $white;
			background: $black;
			padding-left: 20px;
			overflow: hidden;
			white-space: nowrap;

		}
		.country{
			display: inline-block;
			// font-weight: bold;
			letter-spacing:0.05em;
			// padding-left:7px;
			position: relative;
			// @include transition(all 10s);
			left: 0px;
			span{
				display: inline-block;
				padding-right:10px;
				// border-right: 2px solid $pink;
				line-height: 1;
				height: 0.9em;
				position: relative;
				&:before{
					content:" ";
					position: absolute;
					top: 0;
					height: 100%;
					width: 2px;
					background-color: $pink;
					right: 2px;
				}
			}

		}
	}
	.row-title.has-ticker{
		padding-top: 1.2em;
		@media (min-width:480px){
			padding-top: 1.4em;
		}
		@media (min-width:768px){
			padding-top: 1.4em;
		}
		@media (min-width:980px){
			padding-top: 1.7em;
		}
		@media (min-width:1200px){
			padding-top: 1.7em;
		}

	}
}

